import { observable, computed, action } from "mobx"

import BaseStore from "stores/BaseStore"
import LocationsStore from "stores/LocationsStore"
import LocationSummary from "models/LocationSummary"
import UserStore from "stores/UserStore"
import LocationDetailsStore from "stores/LocationDetailsStore"
import { LoadStatus } from "utils/LoadStatus"
import { saveState, loadState, clearState } from "services/savedState"

const localStorageKey = "lastLocationId"

export interface SetCurrentOptions {
  locationId?: string
  fromRoute?: boolean
  fromQuery?: boolean
}

export default class CurrentLocationStore extends BaseStore {
  @observable
  locationDetailsStore?: LocationDetailsStore

  @observable currentLocationId?: string
  @observable isInLocationRoute: boolean = false
  @observable hasLocationQuery: boolean = false

  @observable
  status: LoadStatus = "idle"

  // TODO: does this need to be here? redundant with secondary?
  @computed get locations() {
    return this.locationsStore.locations
  }
  set locations(locations: LocationSummary[]) {
    this.locationsStore.locations = locations
  }

  // returns / picks a current location for the user
  @computed
  get currentLocation() {
    let loc = this.locations.find(({ id }) => id === this.currentLocationId)
    return loc
  }

  @computed get isHomeLocation() {
    if (!this.userStore.session) {
      // anywhere I lay my head is my home
      return true
    } else {
      return this.userStore.session.locationId === this.currentLocationId
    }
  }

  constructor(
    public locationsStore: LocationsStore,
    public userStore: UserStore
  ) {
    super()

    // here's what this fixes:
    // a. when you go from logged out to logged in, need to make sure locations
    //  get refreshed, but not until they're present
    // b. when you open the location modal and favorite a new location, it
    //  shouldn't blow up the underyling page.
    // Scenarios to test if changing this:
    //  Go to the schedule page, then:
    //  1. Log out, DON'T refresh, log in.
    //  2. Log out, DO refresh, log in.
    //    - location dropdown should show your favorites
    //    - open the location modal should show hearts next to favorites
    //    - favoriting or unfavoriting a location shouldn't cause the page
    //      to refresh

    let wasLoggedIn = false
    this.autorun(() => {
      if (this.userStore.isLoggedIn && !wasLoggedIn) {
        wasLoggedIn = true
        // force "fetch" to be called again
        this.status = "idle"
        // is there anywhere where overwriting is a problem?
        this.locationsStore.replaceLocations(this.userStore.session!.locations)
      } else if (!this.userStore.isLoggedIn) {
        wasLoggedIn = false
      }
    })
  }

  // sets current location. falls back to a default if empty
  @action.bound
  setCurrent(options: SetCurrentOptions = {}) {
    // even if not in the query we want to add to the query if we change loc
    this.hasLocationQuery = !!options.fromQuery

    // we have an explicit location id passed, don't use fallbacks
    if (options.locationId) {
      this.isInLocationRoute = !!options.fromRoute
      this.currentLocationId = options.locationId
      this.storeLocation()
      return
    }

    // if no locationId was passed, then the location _isn't_ in the route
    this.isInLocationRoute = false

    // try localstorage, then user's home location, then the nearest
    const storedLocationId = this.loadStoredLocation()
    if (storedLocationId) {
      this.currentLocationId = storedLocationId
    } else if (this.userStore.session) {
      this.currentLocationId = this.userStore.session.locationId
      this.storeLocation()
    } else if (this.locations.length) {
      this.currentLocationId = this.locations[0].id
      this.storeLocation()
    }

    // not going to fallback here, b/c we should never have to.
    // sentry will tell us if there's an error

    if (this.currentLocationId)
      this.locationsStore.brandStore.track.locationChange(
        this.currentLocationId
      )
  }

  @action
  fetch() {
    if (!this.currentLocationId) {
      throw new Error("Attempted to fetch locations without a currentLocation")
    }

    if (this.currentLocation) {
      this.status = "loaded"
      return Promise.resolve(this.currentLocation!)
    }

    this.disposeDetailsStore()
    this.locationDetailsStore = new LocationDetailsStore(this.currentLocationId)
    this.status = "loading"

    return this.locationDetailsStore
      .fetch()
      .then(res => {
        // handles the location id being changed on the server-side
        const idMismatch = res.data.location.id !== this.currentLocationId
        if (idMismatch) {
          this.setCurrent({ locationId: res.data.location.id })

          if (this.userStore.session) {
            // if logged-in, we need to go get the user a new session
            return this.userStore
              .fetch()
              .then(() => this.handleSuccess(res.data.location))
          }
        }
        // might make sense to store after success but before returning if
        // mismatch
        return this.handleSuccess(res.data.location)
      })
      .catch(ex => {
        clearState(localStorageKey, true)
        this.status = "error"
        throw ex
      })
  }

  @action.bound
  handleSuccess(location: LocationSummary) {
    this.currentLocationId = location.id
    this.locationsStore.addLocations([location])
    this.status = "loaded"
    return this.currentLocation!
  }

  storeLocation(locationId?: string) {
    if (locationId || this.currentLocationId) {
      saveState(localStorageKey, locationId || this.currentLocationId, true)
    }
  }

  loadStoredLocation() {
    return loadState(localStorageKey, true)
  }

  dispose() {
    this.disposeDetailsStore()
    super.dispose()
  }

  private disposeDetailsStore() {
    if (this.locationDetailsStore) {
      this.locationDetailsStore.dispose()
      this.locationDetailsStore = undefined
    }
  }
}

// TODO: unused, but should probably make it back in somewhere
// handleGeolocateClick = () => {
//   if ("geolocation" in navigator) {
//     // TODO: stash this, maybe forever
//     navigator.geolocation.getCurrentPosition(
//       position => {
//         console.log("current position", position)
//         this.locationsStore.fetch({ position })
//       },
//       err => {
//         console.error(err)
//       }
//     )
//   }
// }
