import { JsonObject, JsonProperty } from "json2typescript"
import DateConverter from "utils/DateConverter"
import { Moment } from "moment"

export type OpenStatus = "coming_soon" | "open"

@JsonObject("LocationSummary")
export default class LocationSummary {
  /**
   * ID of location, globally unique.
   */
  @JsonProperty("id", String)
  id: string = undefined!

  /**
   * ID of the brand
   */
  @JsonProperty("brand_id", String)
  brandId: string = undefined!

  /**
   * Public-facing name of the location. Defined in Yext.
   */
  @JsonProperty("name", String)
  name: string = undefined!

  /**
   * Currently redundant with `coming_soon`, but added this enum in case there
   * end up being states besides open and coming soon.
   */
  @JsonProperty("open_status", String)
  openStatus: OpenStatus = undefined!

  /**
   * Open date for coming_soon locations.
   */
  @JsonProperty("open_date", DateConverter, true)
  openDate?: Moment = undefined

  /**
   * Probably need ClubReady's input on what this means as well.
   */
  @JsonProperty("coming_soon", Boolean)
  comingSoon: boolean = undefined!

  /**
   * Indicated that the schedule for this locations should be disabled, while still allowing private bookings for users with direct links. Schedule-hiding should be implemented on the frontend; this flag does not change the behavior of any schedule endpoints.
   */
  @JsonProperty("hide_schedule", Boolean)
  hideSchedule: boolean = undefined!

  /**
   * If true, users will not be able to check in to classes at this studio via the app. Attempting to [check in](#operation/checkins_create) to a class at this studio will result in a 404 error.
   */
  @JsonProperty("disable_checkins", Boolean)
  disableCheckins: boolean = undefined!

  @JsonProperty("address", String, true)
  address?: string = undefined

  /**
   * Optional second line of street address.
   */
  @JsonProperty("address2", String, true)
  address2?: string = undefined

  @JsonProperty("city", String, true)
  city?: string = undefined

  @JsonProperty("state", String, true)
  state?: string = undefined

  @JsonProperty("zip", String, true)
  zip?: string = undefined

  @JsonProperty("country_code", String)
  countryCode: string = undefined!

  /**
   * Distance to the location in miles from the user's location (if provided.)
   */
  @JsonProperty("distance", Number, true)
  distance?: number = undefined

  /**
   * The TZInfo identifier of the location's time zone.
   */
  @JsonProperty("timezone", String)
  timezone: string = undefined!

  /**
   * A single email address for the location in case you don't want to do with an array of them.
   */
  @JsonProperty("email", String, true)
  email?: string = undefined

  /**
   * The main phone number of the location.
   */
  @JsonProperty("phone", String, true)
  phone?: string = undefined

  @JsonProperty("lat", Number)
  lat: number = undefined!

  @JsonProperty("lng", Number)
  lng: number = undefined!

  /**
   * A custom sort order for locations.
   */
  @JsonProperty("seq", Number)
  seq: number = undefined!

  /**
   * URL of an html version of the waiver.
   */
  @JsonProperty("waiver_url", String)
  waiverUrl: string = undefined!

  /**
   * URL of the location's website, if any.
   */
  @JsonProperty("site_url", String, true)
  siteUrl?: string = undefined

  /**
   * `page_path` of the page on HubSpot.
   */
  @JsonProperty("site_slug", String)
  siteSlug: string = undefined!

  /**
   * Whether the site is on ClubReady and/or XPO.
   */
  @JsonProperty("is_external", Boolean, true)
  isExternal?: boolean = undefined

  /**
   * CycleBar-specific site subdomain.
   */
  @JsonProperty("external_site_slug", String, true)
  externalSiteSlug?: string = undefined

  /**
   * ClubReady id of studio. Nullable just in case, but should almost always be present.
   */
  @JsonProperty("clubready_id", String)
  clubreadyId: string = undefined!

  stateName?: string

  /**
   * list of promo slots this location has filled in
   */
  @JsonProperty("promo_slots", [String], true)
  promoSlots?: Array<string> = undefined

  /**
   * Whether the studio is a VOD-only studio
   */
  @JsonProperty("vod_studio", Boolean, true)
  vodStudio?: boolean = undefined

  /**
   * Whether the health check for Physical classes is skipped
   * for bookings at this location
   */
  @JsonProperty("disable_health_check", Boolean)
  disableHealthCheck: boolean = undefined!

  /**
   * Whether this location has a custom covid-19 waiver enabled
   * and required for all members (one time) at sign-in
   */
  @JsonProperty("covid_waiver_enabled", Boolean)
  covidWaiverEnabled: boolean = undefined!

  @JsonProperty("la_fitness", Boolean)
  laFitness: boolean = undefined!

  @JsonProperty("la_fitness_warning", String, true)
  laFitnessWarning: string = undefined!

  @JsonProperty("la_fitness_description", String, true)
  laFitnessDescription: string = undefined!

  @JsonProperty("la_fitness_link", String, true)
  laFitnessLink: string = undefined!

  @JsonProperty("la_fitness_link_url", String, true)
  laFitnessLinkUrl: string = undefined!

  /**
   * This location's covid-19 waiver, in HTML
   */
  @JsonProperty("covid_waiver", String, true)
  covidWaiver: string = undefined!

  /**
   * Message that appears when a customer successfully adds an ACH payment
   */
  @JsonProperty("ach_promo_message", String, true)
  achPromoMessage?: string = undefined

  /**
   * The type of location. For now, this is just for Rumble locations
   */
  @JsonProperty("location_type", String, true)
  locationType?: string = undefined

  get fullAddress() {
    return `${this.address}${this.address2 ? " " + this.address2 : ""}, ${
      this.city
    }, ${this.state} ${this.zip}`
  }

  get directionsUrl() {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      this.fullAddress
    )}`
  }
}

@JsonObject("Coordinates")
class Coordinates {
  @JsonProperty("lat", Number)
  lat: number = undefined!

  @JsonProperty("lng", Number)
  lng: number = undefined!
}

@JsonObject("LocationsMeta")
export class LocationsMeta {
  @JsonProperty("coordinates", Coordinates, true)
  coordinates?: Coordinates = undefined
}
