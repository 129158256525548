import React, { useState } from "react"
import {
  Link,
  NavLink,
  RouteComponentProps,
  withRouter,
  NavLinkProps,
  match as Match,
} from "react-router-dom"
import { matchPath } from "react-router";
import { inject, observer } from "mobx-react"
import { observable } from "mobx"
import { Location, UnregisterCallback } from "history"
import * as cx from "classnames"
import capitalize from "lodash/capitalize"

import BrandStore from "stores/BrandStore"
import UserCoordinatesStore from "apps/book/stores/xpass/UserCoordinatesStore"
import LocationPicker from "apps/location_finder/components/LocationPicker"
import Svg from "components/Svg"
import LinkOrAnchor from "components/LinkOrAnchor"
import { clearState } from "services/savedState"
import { BookClassNavFilters } from "apps/book/components/xpass/BookClassNavFilters"

interface NavItemProps extends NavLinkProps {
  label: string
  labelDisplay?: Function
  isVisible: boolean
  childLinks?: NavItemProps[]
}

export interface Props extends RouteComponentProps {
  hideNavigation?: boolean
  store?: BrandStore
  isSingleCheckout?: boolean
}

@inject((store: BrandStore) => ({ store }))
@observer
class MainMenu extends React.Component<Props, {isSingleCheckout: boolean}> {
  @observable isMenuOpen = false
  @observable userCoordinatesStore = new UserCoordinatesStore()

  unsubscribeRouteChange?: UnregisterCallback = undefined

  componentDidUpdate() {
    const { userStore, isXponential } = this.props.store!

    if (isXponential && userStore.isLoggedIn) {
      if (this.userCoordinatesStore && !this.userCoordinatesStore.userCoordinates) {
        this.userCoordinatesStore.fetch()
      }
    }
  }

  componentDidMount() {
    this.unsubscribeRouteChange = this.props.history.listen(
      (location, action) => {
        if (location.pathname !== this.props.location.pathname) {
          this.isMenuOpen = false
        }
      }
    )
  }
  componentWillUnmount() {
    if (this.unsubscribeRouteChange) this.unsubscribeRouteChange()
  }

  handleLogoutClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    clearState("covidWaiverAccepted")
    this.props.store!.authStore.signOut("", false, "/")
    this.props.store!.track.event("account_tap logout", {
      loc: this.props.store!.locStore.currentLocation,
    })
  }

  handleToggleMenu = () => {
    this.isMenuOpen = !this.isMenuOpen
  }

  public render() {
    // const { hideNavigation } = this.props
    const {
      userStore,
      brandId,
      brand,
      locStore,
      uiStore,
      copy,
      settings,
      styles,
      styleClasses,
      isXponential,
    } = this.props.store!
    const { isLoggedIn } = userStore
    const { serviceBooking } = settings
    const isComingSoon =
      locStore.currentLocation && locStore.currentLocation.comingSoon
    const isXpassBookingPage = isXponential && window.location.pathname.includes("/book/")
    const showSearchBar = isXponential && window.location.pathname === "/book/xponential-xpass"
    const showClassPoints = this.props.store!.settings.showClassPoints
    const bookLink =
      isXponential
        ? `/book`
        : locStore.currentLocation && serviceBooking
        ? `/book/${locStore.currentLocation.id}/services`
        : `/book`

    const challengeLink = {
      label: "Challenges",
      to: `/challenges`,
      exact: false,
      isVisible: isLoggedIn && !isComingSoon,
    }
        
    const childLinks: NavItemProps[] = [
      { label: "My Dashboard", to: `/`, exact: true, isVisible: isLoggedIn },
      {
        label: "My Schedule",
        to: `/bookings`,
        exact: false,
        isVisible: isLoggedIn && !isComingSoon,
      },
      { label: "My Account", to: `/account`, exact: false, isVisible: isLoggedIn }
    ]

    const links: NavItemProps[] = [
      { label: "Me", to: `/`, exact: true, isVisible: isLoggedIn, childLinks: childLinks },
      {
        label: copy.bookingPageLabel.length > 0 ? "Book a Class" : `Book a ${copy.seat}`,
        to: bookLink,
        exact: false,
        isVisible: isLoggedIn && !isComingSoon,
        isActive: (_match: Match, location: Location) =>
          location.pathname.substring(0, "/book".length) === "/book" &&
          location.pathname.substring(0, "/bookings".length) !== "/bookings",
      },
      {
        label: isXponential ? copy.mainMenuClasses : `Buy ${capitalize(copy.mainMenuClasses)}`,
        to: `/buy`,
        exact: false,
        isVisible: isLoggedIn,
      },
      {
        label: 'ClassPoints',
        to: `/classpoints`,
        exact: false,
        isVisible: isLoggedIn && !isComingSoon && showClassPoints && !isXponential,
      },
      {
        label: "Rewards",
        to: `/rewards`,
        exact: false,
        isVisible: isXponential && isLoggedIn && !isComingSoon,
      },
      {
        label: copy.activity,
        to: `/history`,
        exact: false,
        isVisible: isLoggedIn && !isComingSoon,
      },
      ...(isXponential ? [] : [challengeLink]),
      {
        label: copy.vodPlatform,
        labelDisplay: () => (
          <React.Fragment>
            {copy.vodPlatform}
          </React.Fragment>
        ),
        to: "/plus",
        exact: false,
        isVisible: isLoggedIn && (brand.showVodMenu || false),
      },

      // { label: "FAQ", to: `/faq`, exact: false },
    ].filter(link => link.isVisible)

    const hideNavigation = !uiStore.nav.navLinksShow || !isLoggedIn
    const hideSignOut = !uiStore.nav.navLinksShow || !isLoggedIn
    const lockLocation = uiStore.nav.lockLocation

    return (
      <>
        <div
          className={cx(
            "main-menu",
            "fixed-top",
            styleClasses.MainMenu__container,
            {
              "main-menu--open": this.isMenuOpen,
            }
          )}
        >
          <header className={`container position-relative justify-content-md-${isXpassBookingPage ? "between" : "center"} d-lg-flex p-0`}>
            <div className="main-menu__controls d-flex justify-content-between align-items-center p-3 px-lg-0 py-lg-1">
              {this.props.isSingleCheckout &&
                <div className="main-menu__brand mr-3 text-decoration-none text-nowrap">
                  <img
                    className="main-menu__logo"
                    src={styles.logoUrl}
                    alt={`${brand.name} Logo`}
                  />
                </div>
              }
              {!this.props.isSingleCheckout &&
                <LinkOrAnchor
                  className="main-menu__brand mr-3 text-decoration-none text-nowrap"
                  to="/"
                  external={false}
                >
                  <img
                    className="main-menu__logo"
                    src={styles.logoUrl}
                    alt={`${brand.name} Logo`}
                  />
                </LinkOrAnchor>
              }

              {(!hideNavigation || isLoggedIn) && (
                <a
                  className="d-lg-none"
                  href="#menu"
                  onClick={this.handleToggleMenu}
                >
                  {this.isMenuOpen ? (
                    <Svg name={isXponential ? "hamburger-x-xpass" : "hamburger-x"} size="32" />
                  ) : (
                    <Svg name={isXponential ? "hamburger-xpass" : "hamburger"} size="32" />
                  )}
                </a>
              )}
            </div>

            {showSearchBar && !this.isMenuOpen ? (
              <BookClassNavFilters 
                store={this.props.store!}
                activeTab={this.props.store!.uiStore.nav.activeTab}
              />
            ) : (
            /* this should be hidden for logged-out users but breaks stuff right now */
            <div className="main-menu__locations d-flex align-items-center mr-lg-auto">
              <LocationPicker disabled={lockLocation} />
            </div>
            )}
           
            {(!hideNavigation || isLoggedIn) && (
              <div
                className={cx(
                  "main-menu__drawer",
                  "px-3",
                  "d-lg-flex",
                  "pr-lg-0"
                )}
              >
                {isLoggedIn && !hideSignOut && (
                  <div
                    className={cx(
                      "main-menu__user",
                      "d-flex",
                      "justify-content-between",
                      "align-items-end",
                      "py-4",
                      "align-items-lg-center",
                      "order-lg-2",
                      "ml-lg-3",
                      "py-lg-0"
                    )}
                  >
                    <div className={`d-lg-none`}>
                      <h2 className={`m-0`}>{userStore.session!.fullName}</h2>
                      <span>{userStore.session!.email}</span>
                    </div>

                    <a
                      className={cx(
                        "text-decoration-none",
                        "px-3",
                        "px-lg-4",
                        "py-lg-3",
                        "d-flex",
                        "justify-content-between",
                        "align-items-center",
                        isXponential ? "text-dark" : "text-black-50",
                        "text-nowrap"
                      )}
                      href="#sign-out"
                      onClick={this.handleLogoutClick}
                    >
                      <span className="pr-2">Sign Out</span>
                      <Svg name="caret-right" size="12" />
                    </a>
                  </div>
                )}

                {!hideNavigation && links.length > 0 && (
                  <nav
                    className={cx(
                      "main-menu__navigation",
                      " d-flex",
                      "flex-column",
                      "py-4",
                      "flex-lg-row",
                      "align-items-lg-stretch",
                      "order-lg-1",
                      "py-lg-0"
                    )}
                  >
                    {links.map((link, index) => (
                        <NavItem key={index} link={link} />
                      )
                    )}
                  </nav>
                )}
              </div>
            )}
          </header>
        </div>
      </>
    )
  }
}

export default withRouter(MainMenu)

const NavItem = ({link}: {link: NavItemProps}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const {
    label,
    labelDisplay,
    isVisible: hidden,
    childLinks,
    ...linkProps
  } = link

  const toggleMobileMenu = () => {
    if (window.innerWidth < 992) {
      setMobileMenuOpen(!mobileMenuOpen)
    }
  }

  const isOnChild = (childLinks: NavItemProps[]) => {
    return Object.entries(childLinks).some(([index, val]) => {
      return val.exact 
        ? location.pathname === val.to
        : location.pathname.startsWith(val.to as string)
    })
  }

  return (
    <>
      {childLinks && (
        <div 
          className="main-menu__item d-flex flex-column align-items-center"
          onMouseEnter={() => setMenuOpen(true)}
          onMouseLeave={() => setMenuOpen(false)}
          onClick={toggleMobileMenu}
        >
          <NavLink
            key={`mainMenuLink:${label.replace(/\s*/g, "")}`}
            className={cx(
              "main-menu__link",
              "d-flex",
              "justify-content-between",
              "align-items-center",
              "p-2",
              "text-black-50",
              "text-nowrap",
              "text-decoration-none",
              "w-100",
              "h-100",
              "has-children",
              {"open": menuOpen},
              {"mobile-open": mobileMenuOpen},
              {"active": isOnChild(childLinks)}
            )}
            {...linkProps}
            onClick={e => e.preventDefault()}
          >
            {(labelDisplay && labelDisplay()) || label}
            <Svg
              className="main-menu__submenu-arrow ml-2"
              name="caret-down"
              size="12"
            />
          </NavLink>
          <ul className={cx("menu-item__submenu", {"open": menuOpen, "mobile-open": mobileMenuOpen})}>
            {childLinks.map((link, index)=> (
              <li key={`li-${index}`}><NavItem key={`sub-${index}`} link={link} /></li>
            ))}
          </ul>
        </div>
      )}
      {!childLinks && (
        <NavLink
          key={`mainMenuLink:${label.replace(/\s*/g, "")}`}
          className={cx(
            "main-menu__link",
            "d-flex",
            "justify-content-between",
            "align-items-center",
            "p-2",
            "text-black-50",
            "text-nowrap",
            "text-decoration-none"
          )}
          {...linkProps}
        >
          {(labelDisplay && labelDisplay()) || label}
          <Svg
            className="d-lg-none"
            name="caret-right"
            size="12"
          />
        </NavLink>
      )}
    </>
  )
}
