import LocationSummary from "models/LocationSummary"
import { Location } from "apps/book/models/xpass/Location"
import ScheduleEntry from "models/ScheduleEntry"
import XpassScheduleEntry from "apps/book/models/xpass/ScheduleEntry"
import Booking from "models/Booking"
import XpassBooking from "models/xpass/XpassBooking"
import ServiceBooking from "apps/book/models/ServiceBooking"
import BookingStatus from "models/BookingStatus"
import Offer from "models/Offer"
import Package, { PackageType, PackageInterval } from "models/Package"
import Purchase from "apps/account/models/Purchase"
import UserProfile from "models/UserProfile"
import { UserSession } from "models/UserSession"
import Goal from "models/Goal"
import PackagePlan from "models/PackagePlan"

export interface UserSessionSchema {
  email: string
  location: string
  hasPaymentSource: boolean
  memberType: string
  hasPastDue: boolean
  inDebtCollection: boolean
  clubreadyId: string
  brand: string
  isFrozen: boolean
}

export function sessionSchema(session: UserSession): UserSessionSchema {
  return {
    email: session.email,
    location: session.locationId,
    hasPaymentSource: !!session.hasPaymentSource,
    memberType: session.memberType,
    hasPastDue: !!session.hasPastDue,
    inDebtCollection: !!session.inDebtCollection,
    clubreadyId: session.clubreadyId,
    brand: session.brandId,
    isFrozen: session.isFrozen,
    // TODO: this should be MEMBER type not memberSHIP type
    // "membership type": session.memberType,
    // "home studio": session.locationId,
  }
}

// TODO: update?
export function profileSchema(profile: UserProfile) {
  return {
    city: profile.city,
    region: profile.state,
    zipcode: profile.zip,
    gender: profile.gender,
    DOB: profile.birthDate,
    "leaderboard opt-in": !profile.hideMetrics,
  }
}

// currently unused
export type BaseSchema = {
  path?: string
  lastPath?: string
}

export interface LocationSchema {
  locationId: string
  locationName: string
  locationComingSoon: boolean
  locationTier?: string // TODO: maybe?

  clubreadyLocationId: string
}

export function locationSchema(location: LocationSummary | Location): LocationSchema {
  return {
    locationId: location.id,
    locationName: location.name,
    locationComingSoon: location.comingSoon,
    clubreadyLocationId: location.clubreadyId,
  }
}

// does amplitude understand dates/times? if not might make sense to do time of day?
export interface ClassSchema {
  classTitle: string

  classCategoryId?: string
  classCategoryName?: string

  classTypeId?: string
  classTypeName?: string

  classStartsAt: string
  classDate: string
  classDuration?: number
  classCapacity?: number

  instructorId?: string

  clubreadyClassName: string
  clubreadyInstructorId?: string

  xpassCredits?: number
}

export function classSchema(entry: ScheduleEntry | XpassScheduleEntry): ClassSchema {
  return {
    classTitle: entry.title,
    classStartsAt: entry.startsAt.toISOString(),
    classDate: entry.dateString,
    classCapacity: entry.capacity,
    ...(entry.location ? locationSchema(entry.location) : {}),
    ...(entry.classCategory
      ? {
          classCategoryId: entry.classCategory.id,
          classCategoryName: entry.classCategory.name,
        }
      : {}),
    ...(entry.instructor
      ? {
          instructorId: entry.instructor.id,
          clubreadyInstructorId: entry.instructor.clubreadyId,
        }
      : {}),

    clubreadyClassName: entry.clubreadyName,
    // classTime: entry.startsAt.format("hh:mma"),
    // TODO: classDuration
  }
}

export interface BookingSchema extends ClassSchema {
  bookingFirstTime?: string
  bookingCreditType?: string
  bookingtoWaitlist: boolean
  bookingLateCancel?: boolean
  firstClassCredits?: number
  xpassCredits?: number
}

export function bookingSchema(booking: Booking | XpassBooking): BookingSchema {
  return {
    ...classSchema(booking.scheduleEntry),

    bookingtoWaitlist: booking.status === "waitlisted",
    bookingLateCancel: !booking.freeCancelable,
    firstClassCredits: booking.firstClassCredits,
    xpassCredits: booking.xpassCredits
    // TODO: bookingFirstTime
    // TODO: bookingCreditType
  }
}

export function bookingStatusSchema(entry: ScheduleEntry | XpassScheduleEntry): BookingSchema {
  return {
    ...classSchema(entry),

    bookingtoWaitlist: !!entry.isFull,
  }
}

export interface GoalSchema {
  goalId: string
  goalTarget?: number
}

export function goalSchema(goal: Goal): GoalSchema {
  return {
    goalId: goal.id,
    goalTarget: goal.target,
  }
}

export interface OfferSchema {
  offerName: string
  offerId: string
  offerSlot: string
  offerPackageId?: string
  offerPackageName?: string
}

export interface PackageSchema {
  packageType: PackageType
  packageName: string
  packageRecurring: boolean
  packageUnlimited: boolean
  packagePrice: number
  packageCurrency: string

  // probably not worth tracking:
  // packageInterval: PackageInterval
  // packageIntervalCount: number
  // packagePaymentCount: number
  // packageIsService: boolean
  // packageIsFree: boolean
  // packageId: string

  clubreadyPackageId: string
  clubreadyPackagePlanId: string
}

export function packageSchema(pkg: Package): PackageSchema {
  return {
    packageType: pkg.packageType,
    packageName: pkg.name,
    packageRecurring: pkg.isRecurring,
    packageUnlimited: pkg.isUnlimited,
    packagePrice: pkg.price.numeric,
    packageCurrency: pkg.price.currencyCode!,

    clubreadyPackageId: pkg.clubreadyId,
    clubreadyPackagePlanId: pkg.clubreadyPlanId,
  }
}

export interface PurchaseSchema {
  purchaseAmount: number
  purchaseLifetimeAmount: number
  purchaseCurrency: string
}

export function purchasePlanSchema(plan: PackagePlan): PurchaseSchema {
  return {
    purchaseAmount: plan.todayTotal.numeric,
    purchaseCurrency: plan.todayTotal.currencyCode!,
    purchaseLifetimeAmount: plan.lifetimePackagePrice.numeric,
  }
}

export type EntitySchema = Partial<
  | LocationSchema
  | ClassSchema
  | BookingSchema
  | OfferSchema
  | PackageSchema
  | PurchaseSchema
  | GoalSchema
>

export interface EventEntities {
  loc?: LocationSummary | Location
  entry?: ScheduleEntry | XpassScheduleEntry
  booking?: Booking
  service_booking?: ServiceBooking
  bookingStatus?: BookingStatus
  offer?: Offer
  pkg?: Package
  plan?: PackagePlan
  purchase?: Purchase
  goal?: Goal
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  cancelledPkg?: Package
  claimedPkg?: Package
  claimedPlan?: PackagePlan
}

export interface RevenueEntities extends EventEntities {
  loc: LocationSummary | Location
  pkg: Package
  plan: PackagePlan
}

// TODO: could enumerate event names on enforce schema on that basis
// could make this a lot nicer if JS had method overloading
export function entityEvent({
  loc,
  entry,
  booking,
  bookingStatus,
  offer,
  pkg,
  purchase,
  plan,
  goal,
  firstName,
  lastName,
  email,
  phone,
  cancelledPkg,
  claimedPkg,
  claimedPlan,
}: EventEntities) {
  let schema: EntitySchema = {}

  if (plan) schema = { ...schema, ...purchasePlanSchema(plan) }
  if (pkg) schema = { ...schema, ...packageSchema(pkg) }
  if (booking) schema = { ...schema, ...bookingSchema(booking) }
  if (bookingStatus)
    schema = { ...schema, ...bookingStatusSchema(bookingStatus.scheduleEntry) }
  if (entry) schema = { ...schema, ...classSchema(entry) }
  if (goal) schema = { ...schema, ...goalSchema(goal) }
  if (loc) schema = { ...schema, ...locationSchema(loc) }
  if (cancelledPkg) schema = { ...schema, ...packageSchema(cancelledPkg) }
  if (claimedPkg) schema = { ...schema, ...packageSchema(claimedPkg) }
  if (claimedPlan) schema = { ...schema, ...purchasePlanSchema(claimedPlan) }

  return schema
}

// map of pages to mobile app screens

export type PageName =
  | "dashboard"
  | "coming soon"
  | "class schedule"
  | "service schedule"
  | "book > pick seat"
  | "book > confirm"
  | "service booking > confirm"
  | "book > success"
  | "service booking > success"
  | "buy > packages"
  | "buy > memberships"
  | "buy > detail"
  | "buy > purchase success"
  | "my schedule"
  | "my schedule > change seat"
  | "performance"
  | "attendance history"
  | "account > memberships"
  | "account > profile"
  | "account > billing"
  | "account > favorites"
  | "auth > sign in"
  | "auth > register"
  | "auth > forgot password"
  | "auth > create password"
  | "auth > email"
  | "offer > sign in"
  | "offer > register"
  | "offer > forgot password"
  | "offer slot > sign in"
  | "offer slot > register"
  | "offer slot > forgot password"
  | "offer > detail"
  | "offer > purchase success"
  | "offer > schedule"
  | "xpass one-page checkout"
  | "checkout"
  | "challenges"
  | "classpoints"
  | "classpoints > rewards"
  | "classpoints > activity"

// prettier-ignore
export const pageScreens: { [key in PageName]?: string } = {
  "dashboard": "home",
  "coming soon": "home",
  "class schedule": "class schedule",

  "book > pick seat": "class detail",
  "book > confirm": "class detail",
  "book > success": "class detail",

  "buy > packages": "buy membership/package",
  "buy > memberships": "buy membership/package",
  "buy > detail": "buy membership/package",
  "buy > purchase success": "buy membership/package",

  "my schedule": "my schedule",

  "performance": "activity",
  "attendance history": "activity",

  "account > memberships": "my memberships",
  "account > profile": "account",
  "account > billing": "account",
  "account > favorites": "account",

  "challenges": "challenges",
}

// default titles for some pages
// set an explicit title in PageTracker to override
// if not supplied here, will default to titleized page name
// prettier-ignore
export const pageTitles: { [key in PageName]?: string } = {
  "class schedule": "Schedule",
  "book > pick seat": "Book",
  "book > confirm": "Book",
  "book > success": "Book",
  "buy > packages": "Packages",
  "buy > memberships": "Memberships",
  "my schedule > change seat": "Change Seat",

  "performance": "Performance",
  "attendance history": "Attendance",

  "account > memberships": "Account > Memberships",
  "account > profile": "Account > Profile",
  "account > billing": "Account > Billing",
  "account > favorites": "Account > Favorites",
  "auth > sign in": "Sign In",
  "auth > register": "Register",
  "auth > forgot password": "Forgot Password",
  "auth > create password": "Create Password",
  "auth > email": "Select Studio",
  "offer > sign in": "Sign In",
  "offer > register": "Register",
  "offer > forgot password": "Forgot Password",
  "offer > schedule": "Schedule",

  // TODO: need dynamic titles
  // "book > pick seat": "",
  // "book > confirm": "",
  // "book > success": "",
  // "buy > detail": "",
  // "buy > purchase success": "",
  // "offer > sign in": "",
  // "offer > register": "",
  // "offer > forgot password": "",
  // "offer > detail": "",
  // "offer > purchase success": "",
}

export type TrackingEvent =
  | "welcome_tap next"
  | "select studio_tap studio"
  | "log in_tap sign in"
  | "create profile_tap next"
  | "sign waiver_tap finish"
  | "authentication_success"
  | "authentication_failure"
  | "home_set goal"
  | "home_tap view performance"
  | "home_tap view history"
  | "home_tap view classes"
  | "home_tap book"
  | "home_tap become a member"
  | "home_tap start vod trial"
  | "home_tap watch video"
  | "home_tap offer link"
  | "class schedule_tap class detail"
  | "class schedule_tap instructor tab"
  | "class schedule_view instructor"
  | "filter_tap save"
  | "class detail_tap instructor"
  | "spot booking_tap confirm"
  | "spot booking_save favorite seat"
  | "favorite_tap to add favorite"
  | "favorite_tap to remove favorite"
  | "booking_tap book"
  | "booking confirmation_tap confirm"
  | "booking_success"
  | "booking_failure"
  | "booking_cancel class"
  | "book_confirm cancellation"
  | "cancel_success"
  | "cancel_failure"
  | "waitlist_add to waitlist"
  | "waitlist_tap confirm"
  | "waitlist_success"
  | "waitlist_failure"
  | "waitlist_remove from waitlist"
  | "waitlist_confirm remove from waitlist"
  | "remove waitlist_success"
  | "remove waitlist_failure"
  | "booking_tap check in"
  | "check in_success"
  | "check in_failure"
  | "packages_tap buy"
  | "purchase_success"
  | "purchase_failure"
  | "packages_view active package"
  | "purchase_add new credit card"
  | "purchase_tap purchase"
  | "purchase_cancel_tap manage membership"
  | "purchase_cancel_tap cancel membership"
  | "purchase_cancel_tap claim offer"
  | "purchase_cancel claim offer success"
  | "purchase_cancel claim offer failure"
  | "purchase_cancel_tap reject offer"
  | "purchase_cancel_tap abort"
  | "purchase_cancel_tap confirm"
  | "purchase_cancel confirm success"
  | "purchase_cancel confirm failure"
  | "map_tap studio detail"
  | "studio detail_tap sign in"
  | "studio detail_tap manage"
  | "my schedule_tap view class"
  | "my schedule_tap book"
  | "activity_tap history"
  | "activity_tap set goal"
  | "account_tap share app"
  | "account_tap rewards"
  | "account_tap studio"
  | "account_enable notification"
  | "account_tap memberships"
  | "memberships_tap history"
  | "memberships_tap view plans"
  | "account_tap logout"
  | "account_tap update leaderboard"
  | "booking_tap update leaderboard"
  | "pageview"
  | "home_tap download ios"
  | "home_tap download android"
  | "home_tap view my schedule"
  | "home_tap view my profile"
  | "home_tap buy package"
  | "home_tap banner link"
  | "purchase_add promo code"
  | "promo code_success"
  | "promo code_failure"
  | "studio menu_open"
  | "studio menu_more"
  | "studio menu_tap studio"
  | "studio map_tap studio"
  | "forgot pw_tap submit"
  | "forgot pw_success"
  | "forgot pw_failure"
  | "create pw_tap submit"
  | "create pw_success"
  | "create pw_failure"
  | "cross club_tap logout"
  | "profile update_submit"
  | "profile update_success"
  | "profile update_failure"
  | "welcome_continue as guest"
  | "add new credit card_success"
  | "add new credit card_failure"
  | "add new bank account success"
  | "add new bank account failure"
  | "health check confirm"
  | "health check cancel"
  | "verification_success"
  | "verification_failure"
  | "xpass_tap change package"
  | "classpoints_tap join today"
  | "classpoints_tap details"
  | "classpoints_tap manage membership"
  | "classpoints_tap send email otp"
  | "classpoints_email verification success"
  | "classpoints_email verification failure"
  | "classpoints_tap resend OTP code"
  | "classpoints_tap opt in"
  | "classpoints_opt in success"
  | "classpoints_opt in failure"
  | "classpoints_tap view ways to earn"
  | "classpoints_tap view my rewards"
  | "classpoints_tap view upcoming rewards"
  | "classpoints_tap view my activity"
  | "classpoints_tap redeem on xpass"
  | "classpoints_tap redeem retail discount"
  | "classpoints_tap redeem guestpass"
  | "classpoints_redeem guestpass success"
  | "classpoints_redeem guestpass failure"
  | "activation code_success"
  | "activation code_failure"
  | "activation code_tap submit"
  | "blocker modal_popup"
  | "blocker modal_tap claim account"

// presently unused
type ClassEvent =
  | "book click"
  | "book select seat"
  | "book confirm"
  | "book abort"
  | "booking change seat"
  | "booking cancel"
