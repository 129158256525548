import { JsonObject, JsonProperty } from "json2typescript"
import LocationSummary from "models/LocationSummary"
import { observable } from "mobx"
import InstructorSummary from "models/InstructorSummary"
import Price from "models/Price"
import ClassCategorySummary from "models/ClassCategorySummary"
import LogbookStatus from "models/LogbookStatus"
import DateConverter from "utils/DateConverter"
import * as moment from "moment"

export type MemberType = "prospect" | "member" | "inactive"

@JsonObject("BrandUser")
export class BrandUser {
  @JsonProperty("access_token", String, true)
  accessToken: string = undefined!

  @JsonProperty("location_id", String, true)
  locationId: string = undefined!
}

// TODO: this should be export default
@JsonObject("UserSession")
export class UserSession {
  /**
   * The user's email
   */
  @JsonProperty("email", String)
  email: string = undefined!

  /**
   * The user's phone
   */
   @JsonProperty("phone_main", String, true)
   phoneMain: string = undefined!

  /**
   * Unique ID of the user in ClubReady
   */
  @JsonProperty("clubready_id", String)
  clubreadyId: string = undefined!

  /**
   * An access token used in subsequent requests.
   */
  @JsonProperty("access_token", String)
  accessToken: string = undefined!

  /**
   * When the access token expires, in seconds since Jan 01 1970 (Unix timestamp)
   */
  @JsonProperty("access_token_expires_at", Number)
  accessTokenExpiresAt: number = undefined!

  @JsonProperty("first_name", String, true)
  firstName?: string = undefined!

  @JsonProperty("last_name", String, true)
  lastName?: string = undefined!

  /**
   * The brand the user is associated with. This will hopefully be an array someday!
   */
  @JsonProperty("brand_id", String)
  brandId: string = undefined!

  /**
   * This is NOT being removed. It's used to indicate the id of the User's home location.
   */
  @JsonProperty("location_id", String)
  locationId: string = undefined!

  /**
   * Total number of classes the user has checked into. For milestones and such. ClubReady `ClassAttendanceCount`. Nullable because we want to make sure not to show `0` if we don't know their attendance count.
   *
   */
  @JsonProperty("attendance_count", Number)
  attendanceCount: number = undefined!

  /**
   * Whether the user's account is temporarily frozen. Should not be able to book or buy if so, needs to call studio to unfreeze account. ClubReady `Frozen`.
   */
  @JsonProperty("is_frozen", Boolean)
  isFrozen: boolean = undefined!

  /**
   * Whether the user has past due invoices. They may be prevented from booking if so. ClubReady `HasPastDue`.
   */
  @JsonProperty("has_past_due", Boolean, true)
  hasPastDue?: boolean = undefined!

  @JsonProperty("past_due_amount", Price, true)
  pastDueAmount?: Price = undefined!

  /**
   * Whether the user has been referred to a third-party debt collector due to non-payment. Will not be able to book or buy if so, possibly should be prevent from logging in. ClubReady `IsInThirdParty`.
   */
  @JsonProperty("in_debt_collection", Boolean, true)
  inDebtCollection?: boolean = undefined!

  /**
   * When their credit card expires, if they have one on file.
   */
  @JsonProperty("cc_expires_at", DateConverter, true)
  ccExpiresAt?: moment.Moment = undefined

  /**
   * When the user's most recent ClubReady membership expired
   */
  @JsonProperty("membership_ended_at", DateConverter, true)
  membershipEndedAt?: moment.Moment = undefined

  /**
   * An array of the user's favorite or default locations. Not in any particular
   * order at the moment. It should always contain at least one item, their home
   * location.
   */
  @observable
  @JsonProperty("locations", [LocationSummary])
  locations: LocationSummary[] = []

  /**
   * An array of the user's favorite instructors. Not in any particular order at the moment.
   *
   */
  @observable
  @JsonProperty("instructors", [InstructorSummary])
  instructors: InstructorSummary[] = []

  /**
   * An array of the user's favorite class categories. Not in any particular order at the moment.
   * Optional for backwards-compatibility reasons. Can be made required at some point
   *
   */
  @observable
  @JsonProperty("class_categories", [ClassCategorySummary], true)
  classCategories: ClassCategorySummary[] = []

  /**
   * Whether or not the user has opted into classpoints
   */
  @JsonProperty("class_points_opted_in", Boolean, true)
  classPointsOptedIn?: boolean = undefined!

  /**
   * What sort of user they are. Unclear if this is right or if there are other
   * kinds. Also unclear on what exactly this implies - if they're a member will
   * they always have an address on file?
   */
  @JsonProperty("member_type", String)
  memberType: MemberType = undefined!

  /**
   * Timestamp that this user accepted their home location's Covid-19 waiver,
   * if any
   */
  @JsonProperty("covid_waiver_accepted_at", DateConverter, true)
  covidWaiverAcceptedAt: moment.Moment = undefined!

  /**
   * Covid-19 Waiver update error
   */
  @JsonProperty("covid_waiver_error", Boolean, true)
  covidWaiverError?: boolean = undefined!

  /**
   * Their leaderboard username, should they wish to appear on the leaderboard.
   */
  @JsonProperty("screen_name", String, true)
  screenName?: string = undefined!

  /**
   * Logbook status
   */
  @JsonProperty("logbook", LogbookStatus, true)
  logbookStatus: LogbookStatus = this.defaultLogbookStatus

  /**
   * Timestamp of the user created at
   */
  @JsonProperty("created_at", DateConverter, true)
  createdAt: moment.Moment = undefined!

  /**
   * If user is connected to Health Kit (for mobile workout stats tracking)
   */
  @JsonProperty("health_kit_connected", Boolean, true)
  healthKitConnected?: boolean = undefined!

  @JsonProperty("birth_date", String, true)
  birthDate?: string = undefined!

  @observable
  @JsonProperty("xpass_user", BrandUser, true)
  xpassUser: BrandUser = undefined!

  @observable
  @JsonProperty("brand_user", BrandUser, true)
  brandUser: BrandUser = undefined!

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get homeLocation() {
    return this.locations.find(({ id }) => id === this.locationId)!
  }

  get hasPaymentSource() {
    return this.ccExpiresAt && this.ccExpiresAt > moment()
  }

  get defaultLogbookStatus() {
    return {
      isConnected: false,
      hasError: false,
    }
  }
}

@JsonObject("OrgUser")
export class OrgUser {
  @JsonProperty("email", String)
  email: string = undefined!

  @JsonProperty("zip", String)
  zip: string = undefined!

  @JsonProperty("first_name", String)
  firstName: string = undefined!

  @JsonProperty("last_name", String)
  lastName: string = undefined!

  @JsonProperty("clubready_id", String)
  clubreadyId: string = undefined!

  @JsonProperty("phone_main", String)
  phoneMain: string = undefined!

  @JsonProperty("birth_date", String, true)
  birthDate: string = undefined!
}
