import axios, { AxiosError } from "axios";
import { action, computed, observable } from "mobx";
import APIStore from "stores/APIStore";

// images & icons 
import aktIcon from "images/xpass/icons/brands/akt.png"
import bftIcon from "images/xpass/icons/brands/bft.png"
import clubPilatesIcon from "images/xpass/icons/brands/clubpilates.png"
import cycleBarIcon from "images/xpass/icons/brands/cyclebar.png"
import pureBarreIcon from "images/xpass/icons/brands/purebarre.png"
import rowHouseIcon from "images/xpass/icons/brands/rowhouse.png"
import rumbleIcon from "images/xpass/icons/brands/rumble.png"
import stretchLabIcon from "images/xpass/icons/brands/stretchlab.png"
import strideIcon from "images/xpass/icons/brands/stride.png"
import yogaSixIcon from "images/xpass/icons/brands/yogasix.png"
import kinrgyIcon from "images/xpass/icons/brands/kinrgy.png"

import aktPreview from "images/xpass/brand_previews/akt.jpg"
import bftPreview from "images/xpass/brand_previews/bft.jpg"
import clubPilatesPreview from "images/xpass/brand_previews/clubpilates.jpg"
import cycleBarPreview from "images/xpass/brand_previews/cyclebar.jpg"
import pureBarrePreview from "images/xpass/brand_previews/purebarre.jpg"
import rowHousePreview from "images/xpass/brand_previews/rowhouse.jpg"
import rumblePreview from "images/xpass/brand_previews/rumble.jpg"
import stretchLabPreview from "images/xpass/brand_previews/stretchlab.jpg"
import stridePreview from "images/xpass/brand_previews/stride.jpg"
import yogaSixPreview from "images/xpass/brand_previews/yogasix.jpg"
import kinrgyPreview from "images/xpass/brand_previews/kinrgy.jpg"

const BRANDS = [
    {
      name: "AKT",
      id: "akt",
      logo: aktIcon,
      preview: aktPreview,
      navIcon: "akt",
      iconColor: "#000"
    },
    {
      name: "BFT",
      id: "bft",
      logo: bftIcon,
      preview: bftPreview,
      navIcon: "bft",
      iconColor: "#00B5CB"
    },
    {
      name: "Club Pilates",
      id: "clubpilates",
      logo: clubPilatesIcon,
      preview: clubPilatesPreview,
      navIcon: "clubPilates",
      iconColor: "#5B8AC7"
    },
    {
      name: "CycleBar",
      id: "cyclebar",
      logo: cycleBarIcon,
      preview: cycleBarPreview,
      navIcon: "cycleBar",
      iconColor: "#BF1616"
    },
    {
      name: "Pure Barre",
      id: "purebarre",
      logo: pureBarreIcon,
      preview: pureBarrePreview,
      navIcon: "pureBarre",
      iconColor: "#CB333B"
    },
    {
      name: "Rumble",
      id: "rumble",
      logo: rumbleIcon,
      preview: rumblePreview,
      navIcon: "rumble",
      iconColor: "#D50133"
    }, 
    {
      name: "StretchLab",
      id: "stretchlab",
      logo: stretchLabIcon,
      preview: stretchLabPreview,
      navIcon: "stretchLab",
      iconColor: "#00ACBA"
    }, 
    {
      name: "YogaSix",
      id: "yogasix",
      logo: yogaSixIcon,
      preview: yogaSixPreview,
      navIcon: "yogaSix",
      iconColor: "#005975"
    },
  ]

export interface FilterCategory {
    id: string;
    name: string;
}
interface Intensity extends FilterCategory {
    seq: number | null;
}

interface Brand extends FilterCategory {
    logo: string;
    preview: string;
    navIcon: string;
    iconColor: string;
}

export default class FiltersStore extends APIStore {
    @observable categories: FilterCategory[] = [];
    @observable bodyFocuses: FilterCategory[] = [];
    @observable intensities: Intensity[] = [];
    @observable isLoadingCategories: boolean = false;
    @observable categoriesError: AxiosError | null = null;
    @observable selectedBrands: string[] = [];
    @observable selectedCategories: string[] = [];
    @observable selectedIntensities: string[] = [];
    @observable selectedBodyFocuses: string[] = []
    @observable creditRange: number[] = [0, 100]
    @observable startTimeRange: number[] = [0, 68]
    @observable distance: number = 4
    @observable showMap: boolean = false
    @observable isBookableWithToken: boolean = false
    brands: Brand[] = BRANDS;

    async fetch() {
        const bodyFocusesUrl = `/api/xpass_2/class_type_metadata/body_focuses`
        const categoriesUrl = `/api/xpass_2/class_type_metadata/modalities`
        const intensitiesUrl = `/api/xpass_2/class_type_metadata/intensities`
        this.isLoadingCategories = true;
        try {
            const categoriesResponse = await axios.get(categoriesUrl);
            const bodyFocusesResponse = await axios.get(bodyFocusesUrl);
            const intensitiesResponse = await axios.get(intensitiesUrl);
          this.categories = categoriesResponse.data.data.modalities
          this.bodyFocuses = bodyFocusesResponse.data.data.body_focuses
          this.intensities = intensitiesResponse.data.data.intensities
          this.isLoadingCategories = false;
        } catch (error) {
          this.handleError(error)
          this.isLoadingCategories = false;
        }
      }
    @computed get hasFilters() {
      let count = 0;
      if (this.selectedBrands.length) count++;
      if (this.selectedCategories.length) count++;
      if (this.selectedIntensities.length) count++;
      if (this.selectedBodyFocuses.length) count++;
      if (this.startTimeRange[0] !== 0 || this.startTimeRange[1] !== 68) count++;
      if (this.distance !== 4) count++;
      if (this.isBookableWithToken) count++;
      return count > 0;
    }
    @action
    setSelectedBrands(brand: string) {
      if (this.selectedBrands.includes(brand)) {
        this.selectedBrands = this.selectedBrands.filter(b => b !== brand)
      } else {
        this.selectedBrands = [...this.selectedBrands, brand]
      }
    }
    @action
    setSelectedCategories(categories: string[]) {
      this.selectedCategories = categories
    }
    @action
    setSelectedBodyFocuses(bodyFocuses: string[]) {
      this.selectedBodyFocuses = bodyFocuses
    }
    @action
    setSelectedIntensities(intensities: string[]) {
      this.selectedIntensities = intensities
    }
    @action
    setStartTimeRange(range: number[]) {
      this.startTimeRange = range
    }
    @action
    setDistance(distance: number) {
      this.distance = distance
    }
    @action
    setIsBookableWithToken(isBookableWithToken: boolean) {
      this.isBookableWithToken = isBookableWithToken
    }
    @action
    resetFilters() {
      this.selectedBrands = []
      this.selectedCategories = []
      this.selectedBodyFocuses = []
      this.selectedIntensities = []
      this.startTimeRange = [0, 68]
      this.distance = 4
      this.isBookableWithToken = false
    }
    @action
    toggleMap() {
      this.showMap = !this.showMap
    }
    @action
    hideMap() {
      this.showMap = false
    }
    @action.bound
    handleError(err: any) {
      console.error(err.response)
      if (err.response && err.response.status === 404) {
          return err.response
      }
      throw err
    }
}
