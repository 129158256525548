import { observable, action, computed, runInAction } from "mobx"
import { AxiosResponse, AxiosPromise } from "axios"
import { FormikErrors } from "formik"

import { loadState, saveState, clearState } from "services/savedState"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import FormErrorMiddleware from "services/middleware/FormErrorMiddleware"
import deleteUserCookie from "services/deleteUserCookie"
import UserStore from "stores/UserStore"
import APIStore from "stores/APIStore"
import UserAuthStore from "./xpass/UserAuthStore"
import { serialize } from "helpers/serializationHelpers"
import { OrgUser, BrandUser, UserSession } from "models/UserSession"
import UserRegistration from "apps/auth/models/UserRegistration"
import XpassUserRegistration from "apps/auth/models/xpass/XpassUserRegistration"
import { clearCache } from "services/middleware/MemoizeMiddleware"
import XpassV3FormRequest from "apps/auth/models/xpass/XpassV3FormRequest"

export interface LoginValues {
  locationId: string
  email: string
  password: string
  mobileOauth: boolean
  mobileBrand: string
  mobilePlatform: string
}

export interface ForgotPasswordValues {
  locationId: string
  email: string
}

export type Pathname = string

interface RedirectResponse {
  url?: string
  fallback_url?: string
}

// handles API calls for logging in and out and such
export default class AuthStore extends APIStore {
  // can break this out if more specificity desired
  signInApi = this.createClient(
    [
      ResponseMiddleware(this.handleSuccess),
      DeserializeMiddleware("user", UserSession),
      DeserializeMiddleware("org_user", OrgUser, true),
      DeserializeMiddleware("brand_user", BrandUser, true),
    ],
    // second argument is prepended middlewares, goes on the outside.
    [FormErrorMiddleware]
  )

  // signOutApi = this.createClient([
  //   TokenAuthMiddleware(this.userStore),
  //   ResponseMiddleware(this.handleSignOutSuccess),
  // ])

  resetPasswordApi = this.createClient([], [FormErrorMiddleware])
  isXponential = this.userStore.brandStore.isXponential
  userAuthStore = new UserAuthStore()
  @observable brandLocationId?: string = ""
  @observable brandUser?: BrandUser

  constructor(public userStore: UserStore) {
    super()
  }

  signIn(values: LoginValues, brandLocationId?: string): Promise<AxiosResponse> {
    if (this.isXponential) {
      this.brandLocationId = brandLocationId
     // TODO: This should work for now but this response returns `org_user` and `user` is not exactly the same as UserSession model.
      return this.signInApi.post(
        "/api/xpass/sessions",
        {
          location_id: brandLocationId,
          email: values.email,
          password: values.password,
          from_web: true,
          mobile_oauth: values.mobileOauth,
          mobile_brand: values.mobileBrand || null,
          mobile_platform: values.mobilePlatform,
        },
        { params: { set_cookie: 1 } }
      )
    }
    return this.signInApi.post(
      "/api/sessions",
      {
        location_id: values.locationId,
        email: values.email,
        password: values.password,
        no_goals: "1",
      },
      { params: { set_cookie: 1 } }
    )
  }

  register(profile: UserRegistration | XpassUserRegistration, extra = {}, redirectParams = {}) {
    const user = {
      platform: "web",
      ...serialize(profile),
      ...extra,
      clubready_referral_type_id: this.userStore.isAppleWatch
        ? this.userStore.brandStore.eywReferralTypeId()
        : this.userStore.brandStore.referralTypeId,
    }

    if (this.isXponential) {
      return this.signInApi.post(
        "/api/xpass/users",
        { user, from_web: true, ...redirectParams }
      )
    }
    return this.signInApi.post(
      "/api/user_profiles",
      { user },
      { params: { set_cookie: "1", no_goals: "1" } }
    )
  }

  registerFromCheckout(profile: XpassV3FormRequest, extra = {}, redirectParams = {}) {
    const user = {
      platform: "web",
      ...serialize(profile),
      ...extra,
      clubready_referral_type_id: this.userStore.isAppleWatch
        ? this.userStore.brandStore.eywReferralTypeId()
        : profile.clubreadyReferralTypeId || this.userStore.brandStore.referralTypeId,
    }

    return this.signInApi.post(
      "/api/user_profiles",
      { user },
      { params: { set_cookie: "1", no_goals: "1" } }
    )
  }

  authWithoutPassword(profile: XpassV3FormRequest, extra = {}, redirectParams = {}) {
    const user = {
      platform: "web",
      token: window.globals.spcUserProfilesToken,
      is_prospect: true,
      ...serialize(profile),
      ...extra,
      clubready_referral_type_id: this.userStore.isAppleWatch
        ? this.userStore.brandStore.eywReferralTypeId()
        : profile.clubreadyReferralTypeId || this.userStore.brandStore.referralTypeId,
    }
    return this.signInApi.post(
      "/api/spc_user_profiles",
      { user },
      { params: { set_cookie: "1", no_goals: "1" } }
    )
  }

  @action
  forgotPassword(values: ForgotPasswordValues): Promise<AxiosResponse> {
    const path = this.isXponential ?
      '/api/xpass/password_resets' :
      '/api/password_resets'
    const promise = this.resetPasswordApi.post(path, {
      location_id: values.locationId,
      email: values.email,
    }) as AxiosPromise<{ errors?: FormikErrors<ForgotPasswordValues> }>
    return promise
  }

  @action.bound
  signOut(
    locationId?: string,
    rememberEmail?: boolean,
    imperativeRedirect?: string
  ) {
    const userLocationId = locationId || this.userStore.session!.locationId
    if (rememberEmail) {
      // TODO: doesn't do anything yet - need to wire it up in RestrictedRoute
      this.userStore.lastEmail = this.userStore.session!.email
    }

    // if present, the path in imperativeRedirect will
    // supercede the friendly route set by RestrictedRoute.
    // See RestrictedRoute getter redirectPath()
    if (imperativeRedirect) {
      saveState("imperativeRedirect", imperativeRedirect, true)
    }

    this.userStore.session = undefined

    // this is just for the brand site. portal uses no cookies
    deleteUserCookie()

    // clear memoized cache
    clearCache()

    // set the default location to their home store
    // or to optional location if supplied
    this.userStore.brandStore.locStore.storeLocation(userLocationId)
    // window.location.reload()
  }

  // we actually probably don't want to expire their token, which is what this does.
  // signOut(): Promise<AxiosResponse> {
  //   const promise = this.signOutApi.delete("/api/sessions")
  //   this.userStore.session = undefined
  //   return promise
  // }

  @action.bound
  protected handleSuccess(r: AxiosResponse<{ user: UserSession, org_user?: OrgUser, redirect?: RedirectResponse }>) {
    if (r.data.redirect) {
      const redirectUrl = r.data.redirect.url
      const fallbackUrl = r.data.redirect.fallback_url
      const switchUrl = 'https://www.xpass.fit/'
      // To avoid locking the user in the sign in page,
      // Redirect to `url` if it exists
      // Redirect to `fallback_url` (app/play store download link) if `url` doesn't exist and `fallback_url` exists
      // Regardless, redirect to `switchUrl` so that the browser tab doesn't stay on sign in page
      if (redirectUrl) {
        setTimeout(function () { window.location.href = switchUrl }, 2000)
        window.location.href = redirectUrl
        return r
      }

      if (fallbackUrl) {
        setTimeout(function () { window.location.href = switchUrl }, 2000)
        window.location.href = fallbackUrl
        return r
      }
    }

    // delayed so that promise handlers can be run before URL changes
    // needed in order to track the right page and such

    // Redirect user to brand member portal if allowRedirect param and brandRedirectDomain present
    const { brandRedirectDomain, allowRedirect } = this.userAuthStore
    const { user: userSession } = r.data
    if (this.isXponential && userSession.brandUser && allowRedirect && brandRedirectDomain) {
      window.location.href = `${brandRedirectDomain}/brand-sign-in?access_token=${userSession.brandUser.accessToken}`
    }

    setTimeout(() => runInAction(() => {
      this.userStore.session = userSession

      if (this.isXponential && userSession.brandUser) {
        this.brandUser = userSession.brandUser
      }
    }))

    this.userStore.brandStore.clearReferralTypeId()

    // This is set/removed in PaymentV3Page (spc)
    const redirectLink = localStorage.getItem('redirectLink')
    if (redirectLink) {
      localStorage.removeItem('redirectLink')
      const url = new URL(redirectLink);
      const queryParams = new URLSearchParams(url.search);

      // Check if emails match the email in the redirect
      if(queryParams.has('email')){
        if(queryParams.get('email') !== userSession.email){
          queryParams.delete('email')
          window.location.href = `${url.origin}${url.pathname}?${queryParams.toString()}`
        } else {
          window.location.href = redirectLink
        }

      } else {
        window.location.href = redirectLink
      }

      return r
    }

    return r
  }

  // @action.bound
  // protected handleSignOutSuccess(r: AxiosResponse) {
  //   this.userStore.session = undefined
  //   // hard refresh is easiest
  //   window.location.reload()
  //   return r
  // }
}
