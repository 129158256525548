// import User from "models/User"
import { computed, action, observable } from "mobx"
import { loadState, saveState, clearState } from "services/savedState"
import APIStore from "stores/APIStore"
import Axios, { AxiosResponse, AxiosError } from "axios"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import { UserSession, BrandUser } from "models/UserSession"
import { deserialize, serialize } from "helpers/serializationHelpers"
import BrandStore from "stores/BrandStore"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import GenericSummary from "models/GenericSummary"
import LocationSummary from "models/LocationSummary"
import FavoritesStore from "apps/account/stores/FavoritesStore"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import CyclingStatsStore from "apps/history/stores/CyclingStatsStore"
import RowingStatsStore from "apps/history/stores/RowingStatsStore"
import GoalStore from "apps/dashboard/stores/GoalStore"
import HeartRateStatsStore from "apps/history/stores/HeartRateStatsStore"
import StatsStore from "apps/history/stores/StatsStore"
import WorkoutStatsStore from "apps/history/stores/WorkoutStatsStore"

// The store is for handling user data not specifically related to login
export default class UserStore extends APIStore {
  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("user", UserSession),
    TokenAuthMiddleware(this),
  ])

  @observable session?: UserSession
  @observable brandLocationId?: string
  @observable brandRedirect?: boolean
  // if we want to pre-populate their email after they log out,
  // set it here before they log out
  // TODO: it doesn't do anything yet - see RestrictedRoute
  lastEmail?: string
  isAppleWatch?: boolean

  favoriteClassCategoriesStore: FavoritesStore<Constructor<GenericSummary>>
  favoriteInstructorsStore: FavoritesStore<Constructor<GenericSummary>>
  favoriteLocationsStore: FavoritesStore<Constructor<LocationSummary>>
  favoriteSeatsStore: FavoritesStore<Constructor<GenericSummary>>

  attendanceGoalStore = new GoalStore("attendance", this)
  performanceStore?: StatsStore

  @computed
  get isLoggedIn() {
    return !!this.session
  }

  @computed
  get homeLocation() {
    if (this.session!) {
      return this.brandStore.locationsStore.locations.find(
        ({ id }) => id === this.session!.locationId
      )
    }
    return undefined
  }

  private storeKey: string

  // TODO: "remember me" - session store
  constructor(public brandStore: BrandStore) {
    super()
    // TODO: do we want to stash this all or would it make more sense to just
    // stash the token and fetch from the api?

    // TODO: delete session if it has timed out. compare w/ Date.now()

    this.autorun(() => {
      if (this.session) {
        saveState(this.storeKey, serialize(this.session))

        this.brandStore.track.identify(this.session)
        this.brandStore.uiStore.openAccountWarning(this.session)
      } else {
        clearState(this.storeKey)
      }
    })

    this.storeKey = `${this.brandStore.brandId}:session`
    try {
      const rawSession = loadState(this.storeKey)

      if (rawSession) {
        const session = deserialize(rawSession, UserSession)
        this.session = session
      }
    } catch (ex) {
      console.error("Couldn't deserialize session", loadState(this.storeKey))
      clearState(this.storeKey)
    }

    this.favoriteClassCategoriesStore = new FavoritesStore(
      "class_categories",
      "classCategories",
      GenericSummary,
      this
    )
    this.favoriteInstructorsStore = new FavoritesStore(
      "instructors",
      "instructors",
      GenericSummary,
      this
    )
    this.favoriteLocationsStore = new FavoritesStore(
      "locations",
      "locations",
      LocationSummary,
      this
    )
    this.favoriteSeatsStore = new FavoritesStore(
      "favorite_seats",
      "seats",
      GenericSummary,
      this
    )

    this.performanceStore = new WorkoutStatsStore(this, brandStore.metrics)
  }

  fetch() {
    return this.api.get("/api/sessions", { params: { no_goals: "1" } })
  }

  @action.bound
  handleSuccess(res: AxiosResponse<{ user: UserSession }>) {
    this.session = res.data.user
    return res
  }
}
