import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { observer, inject } from "mobx-react"
import { observable } from 'mobx'
import BrandStore from 'stores/BrandStore'
import TokenDetailStore from 'apps/xpass/stores/TokenDetailStore'
import DealDetailStore from 'apps/xpass/stores/DealDetailStore'
import Svg from 'components/Svg'
import classTokenImgFallback from "images/xpass/xpass-token-fallback.png"
import moment from 'moment'
// icons
import CheckmarkIcon from "images/xpass/icons/checkmark-green.svg"
import CheckmarkIconClipboard from "images/icons/checkmark.svg"
import ClipboardIcon from "images/xpass/icons/clipboard.svg"
import aktIcon from 'images/xpass/icons/brands/rewards/akt.svg'
import bftIcon from 'images/xpass/icons/brands/rewards/bft.svg'
import cycleBarIcon from 'images/xpass/icons/brands/rewards/cyclebar.svg'
import clubPilatesIcon from 'images/xpass/icons/brands/rewards/clubpilates.svg'
import rowHouseIcon from 'images/xpass/icons/brands/rewards/rowhouse.svg'
import strideIcon from 'images/xpass/icons/brands/rewards/stride.svg'
import pureBarreIcon from 'images/xpass/icons/brands/rewards/purebarre.svg'
import yogaSixIcon from 'images/xpass/icons/brands/rewards/yogasix.svg'
import kinrgyIcon from 'images/xpass/icons/brands/rewards/kinrgy.svg'
import stretchLabIcon from 'images/xpass/icons/brands/rewards/stretchlab.svg'
import rumbleIcon from 'images/xpass/icons/brands/rewards/rumble.svg'
import xponentialIcon from 'images/xpass/icons/brands/rewards/xponential.svg'
import closeIcon from "images/xpass/icons/close.svg"

interface RouteParams {
  rewardId: string;
}

export interface Props extends RouteComponentProps<RouteParams> {
  store?: BrandStore
  isReward? : boolean
  isEarned?: boolean
}

const BRANDS = [
  {
    brandKey: "akt",
    brandName: "AKT",
    brandIcon: aktIcon
  },
  {
    brandKey: "bft",
    brandName: "BFT",
    brandIcon: bftIcon
  },
  {
    brandKey: "cyclebar",
    brandName: "CycleBar",
    brandIcon: cycleBarIcon
  },
  {
    brandKey: "clubpilates",
    brandName: "Club Pilates",
    brandIcon: clubPilatesIcon
  },
  {
    brandKey: "purebarre",
    brandName: "Pure Barre",
    brandIcon: pureBarreIcon
  },
  {
    brandKey: "yogasix",
    brandName: "YogaSix",
    brandIcon: yogaSixIcon
  },
  {
    brandKey: "stretchlab",
    brandName: "StretchLab",
    brandIcon: stretchLabIcon
  },
  {
    brandKey: "rumble",
    brandName: "Rumble",
    brandIcon: rumbleIcon
  },
]


@inject((store: BrandStore) => ({ store }))
@observer
export class RewardDetails extends React.Component<Props> {
  rewardId = this.props.match.params.rewardId
  tokenDetailStore = new TokenDetailStore(this.props.store!)
  dealDetailStore = new DealDetailStore(this.props.store!)
  @observable tokenDetail = this.tokenDetailStore.token
  @observable clipboardCopied = false
  state = {
    tokenDetail: this.tokenDetail,
    clipboardCopied: this.clipboardCopied
  }

 async componentDidMount() {
  if (this.props.isReward) {
    this.dealDetailStore.fetch(this.rewardId)
  } else {
    this.tokenDetailStore.fetch(this.rewardId)
    .then((res) => {this.state.tokenDetail = res.data})
  }
  }

  copyToClipboard(code: string) {
    navigator.clipboard.writeText(code)
    this.setState({clipboardCopied: true})
    setTimeout(() => {
      this.setState({clipboardCopied: false})
    }, 1000)
  }

  render() {
    const tokenDetail = this.tokenDetailStore.token

    if (tokenDetail) {
      const {
        name,
        shortDescription,
        bannerImage,
        thumbnailImage,
        expiresOn,
        earnedOn,
        purchasedOn,
        brands,
        firstTimerOnly,
        bookingWindowDays
      } = tokenDetail
      const redeemableAt = brands && brands.length
        ? BRANDS.filter(brand => brands? brands.includes(brand.brandKey) : null)
        : BRANDS

      return (
        <div className="reward-details mt-4 flex">
          <div className="reward-details__left-col">
            <div className="reward-details__left-image position-relative">
              <img src={bannerImage || classTokenImgFallback} alt="xponential token" className="reward-card-image w-100" />
              <img src={CheckmarkIcon} className="reward-details__preview-checkmark" alt="green checkmark" />
            </div>
            <h2 className="reward-details__title mt-3">{name}</h2>
            <div className="reward-details__points mt-2">{earnedOn ? `Earned ${moment(earnedOn).format('MM/DD/YYYY')}` : `Purchased on ${moment(purchasedOn).format('MM/DD/YYYY')}`}</div>
            <div className="reward-details__brand mt-2">
              <img src={thumbnailImage} className="mr-2" />Xponential Fitness
            </div>
            <div className="border-top border-light my-4" />
            <p className="reward-details__description">{shortDescription}</p>
          </div>
          <div className="reward-details__right-col">
            {expiresOn && <p className="reward-details__expiration-date">Expires on {moment(expiresOn).format('MM/DD/YYYY')}</p>}
            <div className="reward-details__unlock-status my-3">
              <a
                className="btn btn-primary w-100"
                href={`/book/xponential-xpass`}
                >
                  Use Token
              </a>
            </div>
            {firstTimerOnly && (
              <div className="reward-details__unlock-status my-3">Valid for first time visits only</div>
            )}
            {bookingWindowDays && bookingWindowDays < 7 && (
              <div className="reward-details__unlock-status my-3">Book up to {bookingWindowDays} days in advance</div>
            )}
            {/* <div className="reward-details__unlock-status">
              <img src={CheckmarkIcon} className="reward-details__unlock-checkmark mr-2" alt="green checkmark" />
                Unlocked with {10} ClassPoints
            </div> */}
            {redeemableAt.length > 0 && (
              <div className="reward-details__redeem-at mt-3">
                <p>Redeem at:</p>
                {redeemableAt.map((brand) => (
                    <div key={brand.brandKey} className="reward-details__website-link flex mt-3 justify-content-start align-items-center">
                      <img src={brand.brandIcon} alt={brand.brandName} className="mr-2" />
                      {brand.brandName}
                    </div>
                  ))}
              </div>
            )}

          </div>
        </div>
        )
    } else if (this.dealDetailStore.deal && this.props.isReward) {
      const { description, endDate, partnerName, partnerUrl, partnerLogo, redeemable, couponCode, title, terms, heroImages, redemptionMethod, url, qrCodeImage } = this.dealDetailStore.deal
      const openTermsModal = () => {
        this.props.store!.uiStore.openModal({
          children:
          <div className="reward-terms-modal text-center">
            <div className="claim-account-modal__close" onClick={this.props.store!.uiStore.closeModal}>
              <img src={closeIcon} alt="Close modal" />
            </div>
            <h3>Offer Terms</h3>
            <p>{terms}</p>
            <button onClick={this.props.store!.uiStore.closeModal} className="btn btn-primary">OK</button>
          </div>,
          modalShow: true,
        })
      }
      return (
        <div className="reward-details mt-4 flex">
          <div className="reward-details__left-col">
            <div className="reward-details__left-image position-relative">
              <img src={heroImages && heroImages[0].url || "https://placehold.co/600x345"} alt={partnerName} className="reward-card-image w-100" />
             {redeemable && <img src={CheckmarkIcon} className="reward-details__preview-checkmark" alt="green checkmark" />}
            </div>
            <h2 className="reward-details__title mt-3">{title}</h2>
            {/* <div className="reward-details__points mt-2">{`${props.isEarned ? "Earned" : "Purchased"} on ${moment(startDate).format('MM/DD/YYYY')}`}</div> */}
            <div className="reward-details__brand mt-2">
              {partnerLogo && <img src={partnerLogo} className="mr-2" />}{partnerName}
            </div>
            <div className="border-top border-light my-4" />
            <p className="reward-details__description">{description}</p>
          </div>
          <div className="reward-details__right-col">
            <p className="reward-details__expiration-date">Expires on {moment(endDate).format('MM/DD/YYYY')}</p>
            <div className="reward-details__unlock-status my-3">
              {redemptionMethod === 'url' && url ? (
                <a
                  className="btn btn-primary w-100"
                  href={url}
                  target="_blank"
                  >
                    Redeem
                </a>
              ) : qrCodeImage && (
                <div>
                  <p>Scan the QR code to Redeem</p>
                  <div className="flex justify-start align-center">
                    <img src={qrCodeImage} alt="deal qr code" />
                  </div>
                </div>
              )}
            </div>
            {/* <div className="reward-details__unlock-status">
              <img src={CheckmarkIcon} className="reward-details__unlock-checkmark mr-2" alt="green checkmark" />
                Unlocked with 10 ClassPoints
            </div> */}
              <div className="reward-details__coupon mt-3">
                <p>Use this coupon code when checking out</p>
                {couponCode && (
                <div className="reward-details__coupon-container position-relative">
                  <div className="reward-details__coupon-code">{couponCode}</div>
                  <img onClick={() => this.copyToClipboard(couponCode)} className="clipboard-icon" src={this.state.clipboardCopied ? CheckmarkIconClipboard : ClipboardIcon} alt="copy to clipboard" />
                </div>
                )}
                {partnerUrl && (
                <a href={partnerUrl} className="reward-details__website-link flex mt-3 justify-content-between align-items-center">Visit Website
                  <Svg className="ml-1" name="caret-right" size="12" />
                </a>
                )}
                <button onClick={openTermsModal} className="reward-details__website-link reward-details__terms-btn flex mt-3 justify-content-between align-items-center">Offer Terms
                  <Svg className="ml-1" name="caret-right" size="12" />
                </button>
              </div>
          </div>
        </div>
        )
    } else {
      return (
        <div>Loading...</div>
      )
    }

    }
}

export default RewardDetails
