import React from "react"
import { inject, observer } from "mobx-react"
import BalancesStore from "apps/buy/stores/xpass/BalancesStore"
import APILoader from "components/wrappers/APILoader"
import BrandStore from "stores/BrandStore"
import { RouteComponentProps } from "react-router-dom"
//icons
import aktIcon from 'images/xpass/icons/brands/rewards/akt.svg'
import bftIcon from 'images/xpass/icons/brands/rewards/bft.svg'
import cycleBarIcon from 'images/xpass/icons/brands/rewards/cyclebar.svg'
import clubPilatesIcon from 'images/xpass/icons/brands/rewards/clubpilates.svg'
import rowHouseIcon from 'images/xpass/icons/brands/rewards/rowhouse.svg'
import strideIcon from 'images/xpass/icons/brands/rewards/stride.svg'
import pureBarreIcon from 'images/xpass/icons/brands/rewards/purebarre.svg'
import yogaSixIcon from 'images/xpass/icons/brands/rewards/yogasix.svg'
import kinrgyIcon from 'images/xpass/icons/brands/rewards/kinrgy.svg'
import stretchLabIcon from 'images/xpass/icons/brands/rewards/stretchlab.svg'
import rumbleIcon from 'images/xpass/icons/brands/rewards/rumble.svg'
import xponentialIcon from 'images/xpass/icons/brands/rewards/xponential.svg'
import CheckmarkIcon from "images/xpass/icons/checkmark-green.svg"
// images
import classTokenImgFallback from "images/xpass/token-fallback-with-logo.png"

const BRANDS = [
  {
    brandKey: "akt",
    brandName: "AKT",
    brandIcon: aktIcon
  },
  {
    brandKey: "bft",
    brandName: "BFT",
    brandIcon: bftIcon
  },
  {
    brandKey: "cyclebar",
    brandName: "CycleBar",
    brandIcon: cycleBarIcon
  },
  {
    brandKey: "clubpilates",
    brandName: "Club Pilates",
    brandIcon: clubPilatesIcon
  },
  {
    brandKey: "purebarre",
    brandName: "Pure Barre",
    brandIcon: pureBarreIcon
  },
  {
    brandKey: "yogasix",
    brandName: "YogaSix",
    brandIcon: yogaSixIcon
  },
  {
    brandKey: "stretchlab",
    brandName: "StretchLab",
    brandIcon: stretchLabIcon
  },
  {
    brandKey: "rumble",
    brandName: "Rumble",
    brandIcon: rumbleIcon
  },
]

export interface Props extends RouteComponentProps {
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class RewardDetails extends React.Component<Props> {
  balancesStore = new BalancesStore(this.props.store!)

  componentDidMount(): void {
    this.balancesStore.fetch()
  }

  render() {
    return (
      <APILoader
        apiStore={this.balancesStore}
        elementSize="page"
        render={() => {
          const tokensCount = this.balancesStore.balances!.purchasedTokenCount
          const tokensCopy = tokensCount === 1 ? "Token" : "Tokens"
          return (
            <div className="reward-details mt-4 flex">
              <div className="reward-details__left-col">
                <div className="reward-details__left-image position-relative">
                  <img src={classTokenImgFallback} alt="xponential token" className="reward-card-image w-100" />
                  <img src={CheckmarkIcon} className="reward-details__preview-checkmark" alt="green checkmark" />
                </div>
                <h2 className="reward-details__title mt-3">{tokensCount} XPASS {tokensCopy}</h2>
                <div className="reward-details__brand mt-3">
                  <img src={xponentialIcon} className="mr-2" />Xponential Fitness
                </div>
                <div className="border-top border-light my-4" />
                <p className="reward-details__description">XPASS token reward. Requires active studio membership to redeem.</p>
              </div>
              <div className="reward-details__right-col">
                <div className="reward-details__unlock-status my-3">
                  <a
                    className="btn btn-primary w-100"
                    href={`/book/xponential-xpass`}
                    >
                      Use Token
                  </a>
                </div>
                  <div className="reward-details__redeem-at mt-3">
                    <p>Redeem at:</p>
                    {BRANDS.map((brand) => (
                        <div key={brand.brandKey} className="reward-details__website-link flex mt-3 justify-content-start align-items-center">
                          <img src={brand.brandIcon} alt={brand.brandName} className="mr-2" />
                          {brand.brandName}
                        </div>
                      ))}
                  </div>
              </div>
          </div>
          )}}
      />
    )
  }
}
