export const brandSlugs: string[] = [
  "akt",
  "bft",
  "clubpilates",
  "cyclebar",
  "purebarre",
  "rumble",
  "stretchlab",
  "yogasix",
]

export const brandsTotal: number = brandSlugs.length
