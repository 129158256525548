import {
  BrandData,
  BrandStyles,
  BrandStyleClasses,
  baseSettings,
  baseStyles,
  baseStyleClasses,
  baseCopy,
  BadgeBitmap,
  BrandCopy,
  HeartRateZoneColors,
  eywAffiliateLinks,
} from "themes/base"

import numberFormatter, { formatNumber } from "helpers/numberFormatter"

import logoUrl from "images/logos/stride-logo.svg"
import badgeImage from "images/badges/stride-badge.svg"
import bannerImage from "images/dashboard/stride-banner-bg.jpg"
import appPromoImage_Video from "images/video/stride-app-promo.png"
import videoLandingLogo from "images/video/stride-go-logo.png"
import appleWatchIcon from "images/apple_health/watch/stride.svg"
import classpointsHero from "images/classpoints/classpoints-hero_stride.png"
import brandIcon from "images/classpoints/brands/SR.svg"

import badge10Active from "images/badges/stride/milestone-1_active.svg"
import badge10Inactive from "images/badges/stride/milestone-1_inactive.svg"
import badge25Active from "images/badges/stride/milestone-2_active.svg"
import badge25Inactive from "images/badges/stride/milestone-2_inactive.svg"
import badge50Active from "images/badges/stride/milestone-3_active.svg"
import badge50Inactive from "images/badges/stride/milestone-3_inactive.svg"
import badge100Active from "images/badges/stride/milestone-4_active.svg"
import badge100Inactive from "images/badges/stride/milestone-4_inactive.svg"
import badge250Active from "images/badges/stride/milestone-5_active.svg"
import badge250Inactive from "images/badges/stride/milestone-5_inactive.svg"
import badge500Active from "images/badges/stride/milestone-6_active.svg"
import badge500Inactive from "images/badges/stride/milestone-6_inactive.svg"
import badge1000Active from "images/badges/stride/stride-logo-badge_active.svg"
import badge1000Inactive from "images/badges/stride/stride-logo-badge_inactive.svg"

// same as $primary in scss
const chartColor = "#6D2685"

const styles: BrandStyles = {
  ...baseStyles,
  badgeImage,
  bannerImage,
  chartColor,
  logoUrl,
  appPromoImage_Video,
  videoLandingLogo,
  appleWatchIcon,
  classpointsHero,
  brandIcon
}

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
}

const copy: BrandCopy = {
  ...baseCopy,
  seat: "Spot",
  seats: "Spots",
}

const brand: BrandData = {
  settings: baseSettings,
  styles,
  styleClasses,
  copy,
  dashboardCards: [],
  badges: [],
  metrics: [],
  summaryMetrics: [],
  heartRateZoneColors: [],
  includeTerms: false,
  eywAffiliateLinks: eywAffiliateLinks["stride"],
  eywLegalLink: "https://www.stridefitness.com/terms-earn-your-watch",
  eywParticipatingLink: "https://www.stridefitness.com/earn-your-watch-locations"
}

brand.metrics = [
  {
    key: "iqPoints",
    label: "Points",
    logo: "perf-akt-target",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "calories",
    label: "Calories",
    logo: "perf-akt-calories",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "avgHr",
    label: "Average HR",
    logo: "perf-akt-hr",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "avgHrPercent",
    label: "Average HR %",
    logo: "perf-akt-hr-perc",
    logoSize: 30,
    format: numberFormatter({ suffix: "%" }),
  },
  {
    key: "highHr",
    label: "High HR",
    logo: "perf-akt-hr",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "highHrPercent",
    label: "Max HR %",
    logo: "perf-akt-hr-perc",
    logoSize: 30,
    format: numberFormatter({ suffix: "%" }),
  },
  {
    key: "maxHr",
    label: "Max HR",
    logo: "perf-akt-hr",
    logoSize: 30,
    format: formatNumber,
  },
]

const badges: BadgeBitmap[] = [
  {
    label: "10 Runs",
    value: 10,
    active: badge10Active,
    inactive: badge10Inactive,
  },
  {
    label: "25 Runs",
    value: 25,
    active: badge25Active,
    inactive: badge25Inactive,
  },
  {
    label: "50 Runs",
    value: 50,
    active: badge50Active,
    inactive: badge50Inactive,
  },
  {
    label: "100 Runs",
    value: 100,
    active: badge100Active,
    inactive: badge100Inactive,
  },
  {
    label: "250 Runs",
    value: 250,
    active: badge250Active,
    inactive: badge250Inactive,
  },
  {
    label: "500 Runs",
    value: 500,
    active: badge500Active,
    inactive: badge500Inactive,
  },
  {
    label: "1000 Runs",
    value: 1000,
    active: badge1000Active,
    inactive: badge1000Inactive,
  },
]
brand.badges = badges

brand.dashboardCards = [
  "AttendanceCard",
  // "MembershipCard",
  "AchievementsCard",
  "ProfileCard",
  "NextClassCard",
  "TodaysClassCard",
  "ClassPointsCard",
  "AppleHealthCard",
  "MobileAppCard",
  "MobileOfferCard",
  "PromoCards",
]

const colors: HeartRateZoneColors[] = [
  { name: "gray", color: "rgb(162, 170, 173)" },
  { name: "black", color: "rgb(44, 42, 41)" },
  { name: "pink", color: "rgb(219, 10, 91)" },
  { name: "orange", color: "rgb(255, 103, 31)" },
  { name: "purple", color: "rgb(135, 23, 157)" },
]
brand.heartRateZoneColors = colors

export default brand
